import React from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import VisuelContent from '../../../components/ContentType/VisuelComponent/VisuelComponent';
import Seo from '../../../components/Seo/Seo';
import useMedias from '../../../hooks/useMedias';
import Layout from '../../../Layout';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';

const PageFooter = ({ data, pageContext }) => {
  const { getImage } = useMedias();

  const { locale } = pageContext;
  const imagesArray = data?.allImages?.edges;
  const pageData = data?.footerData?.edges[0]?.node;
  const metaTags =  pageData.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
   metaTags.forEach((meta) => {
      if(meta.attributes.name === 'title'){
           metaTitle = meta.attributes.content;
      }
      if(meta.attributes.name === 'description'){
           metaDesc = meta.attributes.content;
      }
    });

  return (
    <Layout>
      <Seo
        title={pageData?.field_metatag?.title ? pageData?.field_metatag?.title : metaTitle}
        description={pageData?.field_metatag?.description ? pageData?.field_metatag?.description : metaDesc}
      />
      <div className="page_template">
        <div className="wrapper_page">
          <Breadcrumb
            page_banner={false}
            data={{
              currentPage: { title: pageData?.title, url: '' },
              locale: locale,
            }}
          />
          <TitlePage
            color="color_dark_bleu"
            title={pageData?.title}
            description=""
          />
        </div>
        <section className="section_content">
          {pageData?.relationships?.field_image?.drupal_internal__mid && (
            <VisuelContent
              visuel={getImage(
                imagesArray,
                pageData?.relationships?.field_image?.drupal_internal__mid
              )}
            />
          )}
          <div
            className="wrapper_page_xs"
            dangerouslySetInnerHTML={{ __html: pageData?.body?.processed }}
          ></div>
        </section>
      </div>
    </Layout>
  );
};

PageFooter.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query pageFooterTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    footerData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          status
          field_type
          langcode
          body {
            processed
          }
          path {
            alias
          }
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          relationships {
            field_image {
              drupal_internal__mid
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default PageFooter;
