import * as React from 'react';

import PropTypes from 'prop-types';

import './VisuelComponent.scss';
import useMedias from '../../../hooks/useMedias';

let classNames = require('classnames');

const VisuelContent = ({ visuel, alt, size }) => {
  const { processUrl } = useMedias();

  return (
    <div className={classNames('visuel', 'size_' + size)}>
      <img
        src={processUrl(visuel.uri.url)}
        alt={alt}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </div>
  );
};

const VisuelComponent = ({
  visuel,
  alt,
  aspectRationValue,
  extremeLeft,
  extremeRight,
  size,
  customClass = '',
}) => {
  return (
    <>
      {extremeLeft && visuel ? (
        <div
          className={classNames(
            'visuel_component',
            'cross_wrapper_extreme_left',
            customClass
          )}
        >
          <VisuelContent
            visuel={visuel}
            alt={alt}
            size={size}
            aspectRationValue={aspectRationValue}
          />
        </div>
      ) : extremeRight && visuel ? (
        <div
          className={classNames(
            'visuel_component',
            'cross_wrapper_extreme_right',
            customClass
          )}
        >
          <VisuelContent
            visuel={visuel}
            alt={alt}
            size={size}
            aspectRationValue={aspectRationValue}
          />
        </div>
      ) : (
        visuel && (
          <div className={classNames('visuel_component', customClass)}>
            <VisuelContent
              visuel={visuel}
              alt={alt}
              size={size}
              aspectRationValue={aspectRationValue}
            />
          </div>
        )
      )}
    </>
  );
};

VisuelComponent.defaultProps = {
  visuel: '',
  alt: 'Default Alt',
  size: 'wrapper',
};

VisuelComponent.propTypes = {
  alt: PropTypes.string,
  extremeLeft: PropTypes.bool,
  extremeRight: PropTypes.bool,
  size: PropTypes.string,
};

export default VisuelComponent;
